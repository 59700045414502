(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/product-header/assets/javascripts/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/lb-ui/product-header/assets/javascripts/handlebars-helpers.js');
"use strict";



if (typeof exports === 'object') {
  registerHelpers(require('hbs'));
} else {
  registerHelpers(Handlebars);
}

function registerHelpers(hbs) {
  let currId = 0;
  hbs.registerHelper('product-header-generate-id', () => {
    currId = (currId + 1) % 1000;
    return '';
  });
  hbs.registerHelper('product-header-id', () => currId);
}

 })(window);