(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/product-header/views/product-header.js') >= 0) return;  svs.modules.push('/components/lb-ui/product-header/views/product-header.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.product_header=_svs.lb_ui.product_header||{};
_svs.lb_ui.product_header.templates=_svs.lb_ui.product_header.templates||{};
svs.lb_ui.product_header.templates.product_header = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"product-header-rectangular product-header-rectangular-branding-"
    + alias3(((helper = (helper = lookupProperty(helpers,"branding") || (depth0 != null ? lookupProperty(depth0,"branding") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"branding","hash":{},"data":data,"loc":{"start":{"line":2,"column":75},"end":{"line":2,"column":87}}}) : helper)))
    + " product-header-rectangular-size-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":120},"end":{"line":2,"column":158}}})) != null ? stack1 : "")
    + "\">\n  "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":(depth0 != null ? lookupProperty(depth0,"product") : depth0),"size":100,"emblem":(depth0 != null ? lookupProperty(depth0,"emblem") : depth0),"brand":(depth0 != null ? lookupProperty(depth0,"branding") : depth0)},"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":67}}}))
    + "\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":2,"column":132},"end":{"line":2,"column":140}}}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    return "100";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias4(((helper = (helper = lookupProperty(helpers,"product-header-generate-id") || (depth0 != null ? lookupProperty(depth0,"product-header-generate-id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"product-header-generate-id","hash":{},"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":6,"column":30}}}) : helper)))
    + "\n<div\n  class=\"product-header product-header-branding-"
    + alias4(((helper = (helper = lookupProperty(helpers,"branding") || (depth0 != null ? lookupProperty(depth0,"branding") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"branding","hash":{},"data":data,"loc":{"start":{"line":8,"column":48},"end":{"line":8,"column":60}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"heightMode") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":75}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":35}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"style") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":11,"column":42}}})) != null ? stack1 : "")
    + ">\n  <svg class=\"product-header-polygon\" viewBox=\"0 0 100 50\" preserveAspectRatio=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n    <defs>\n      <linearGradient id=\"product-header-gradient-"
    + alias4(((helper = (helper = lookupProperty(helpers,"product-header-id") || (depth0 != null ? lookupProperty(depth0,"product-header-id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"product-header-id","hash":{},"data":data,"loc":{"start":{"line":15,"column":50},"end":{"line":15,"column":71}}}) : helper)))
    + "\">\n        <stop class=\"product-header-gradient-left\" offset=\"0%\" stop-color=\"transparent\" />\n        <stop class=\"product-header-gradient-center\" offset=\"30%\" stop-color=\"transparent\" />\n        <stop class=\"product-header-gradient-center\" offset=\"70%\" stop-color=\"transparent\" />\n        <stop class=\"product-header-gradient-right\" offset=\"100%\" stop-color=\"transparent\" />\n      </linearGradient>\n    </defs>\n    <polygon points=\"0,0 100,0 100,35.7 50,50 0,35.7\" fill=\"url('#product-header-gradient-"
    + alias4(((helper = (helper = lookupProperty(helpers,"product-header-id") || (depth0 != null ? lookupProperty(depth0,"product-header-id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"product-header-id","hash":{},"data":data,"loc":{"start":{"line":22,"column":90},"end":{"line":22,"column":111}}}) : helper)))
    + "')\" class=\"product-header-polygon-mobile\" />\n    <polygon points=\"0,0 100,0 100,37.5 50,50 0,37.5\" fill=\"url('#product-header-gradient-"
    + alias4(((helper = (helper = lookupProperty(helpers,"product-header-id") || (depth0 != null ? lookupProperty(depth0,"product-header-id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"product-header-id","hash":{},"data":data,"loc":{"start":{"line":23,"column":90},"end":{"line":23,"column":111}}}) : helper)))
    + "')\" class=\"product-header-polygon-tablet\" />\n    <polygon points=\"0,0 100,0 100,40 50,50 0,40\" fill=\"url('#product-header-gradient-"
    + alias4(((helper = (helper = lookupProperty(helpers,"product-header-id") || (depth0 != null ? lookupProperty(depth0,"product-header-id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"product-header-id","hash":{},"data":data,"loc":{"start":{"line":24,"column":86},"end":{"line":24,"column":107}}}) : helper)))
    + "')\" class=\"product-header-polygon-desktop\" />\n  </svg>\n  <div class=\"product-header-box\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"branding") : depth0),"hvs",{"name":"compare","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":31,"column":16}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " height-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"heightMode") || (depth0 != null ? lookupProperty(depth0,"heightMode") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"heightMode","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":45}}}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    return " height-detect";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"class","hash":{},"data":data,"loc":{"start":{"line":10,"column":19},"end":{"line":10,"column":28}}}) : helper)));
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"style") || (depth0 != null ? lookupProperty(depth0,"style") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"style","hash":{},"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":33}}}) : helper)))
    + "\"";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"size":"100","emblem":(depth0 != null ? lookupProperty(depth0,"emblem") : depth0),"brand":"fulltraff"},"data":data,"loc":{"start":{"line":28,"column":6},"end":{"line":28,"column":64}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"size":"100","emblem":(depth0 != null ? lookupProperty(depth0,"emblem") : depth0),"brand":(depth0 != null ? lookupProperty(depth0,"branding") : depth0)},"data":data,"loc":{"start":{"line":30,"column":6},"end":{"line":30,"column":61}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rectangular") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":34,"column":7}}})) != null ? stack1 : "");
},"useData":true});
Handlebars.partials['components-lb_ui-product_header-product_header'] = svs.lb_ui.product_header.templates.product_header;
})(svs, Handlebars);


 })(window);